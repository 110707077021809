import React, { useCallback, useEffect, useRef, useState } from 'react';
import {Form, Table,Input, Button, Space,Breadcrumb, message} from 'antd';
import {API,getFetch} from '../../axios/api';
import axios from 'axios';
import {utils,fileType} from '../../util/util';
import styles from './style.module.css';
import ReactAudioPlayer from 'react-audio-player';

function CallList() {
  const [exportLoading,setExportLoading] = useState(false);
  
  const [callParam,setCallParam] = useState({pageNum:1,pageSize:15});
  const [dataSource,setDataSource] = useState([]);
  const [callData,setCallData] = useState({pageSize:15,pageNum:0,totalRows:0})
  const [form] = Form.useForm();
  const aEle = useRef(null);
  const columnsData = [
    {title: '呼叫ID',dataIndex:'id',width:120},
    {title: '客户ID',dataIndex:'customerId',width:120},
    {title: '客户姓名',dataIndex:'customerName',width:120},
    {title: '客户手机',dataIndex:'mobile',width:120},
    {title: '呼出人',dataIndex:'callOutUserName',width:120},
    {title: '所属小组',dataIndex:'teamName',width:120},
    {title: '呼出时间',dataIndex:'callTime',width:200},
    {title: '音频',dataIndex:'audioUrl',width:200,render:(text, record, index)=>{
        return(<><ReactAudioPlayer
          src={text}
          autoPlay={false}
          controls={true}
          style={{height:24,width:'130'}}/></>)
    }},
  ];
  useEffect(()=>{
    getCallListEvent()
  },[])
  const getCallListEvent = (params)=>{
    const param = {...callParam,...params};
    setCallParam(param)
    getFetch(API.callRecordList,param).then((res)=>{
      if(res){
        setDataSource([...res.data.data.records])
        setCallData({...res.data.data})
      }
    })
  }
  const pagesChangeEvent = (e)=>{
    getCallListEvent({pageNum:e})
  }
  const onFinishEvent = (e)=>{
    getCallListEvent({pageNum:1,...e});
  }
  const exportEvent = useCallback(()=>{
    setExportLoading(true)
    const params = {};
    if(form.getFieldValue('keyWord')){
      params['keyWord'] = form.getFieldValue('keyWord');
    }
    axios.get(`${process.env.REACT_APP_DOMAIN_API}${API.callRecordExport}`,
      {
        params:{...params},
        responseType: 'blob',
        headers:{
          'Authorization':'Bearer ' + utils.getCookie('token')
        }
    }).then((res)=>{
      if(!res.data.resultCode){
        fileType.toExcel(res)
      }else{
        message.error(res.data.resultMsg)
      }
    }).finally(()=>{
      setExportLoading(false)
    })
  },[])
  return (
    <div>
      <Breadcrumb>
        <Breadcrumb.Item className={styles.breadCont}>呼叫记录</Breadcrumb.Item>
      </Breadcrumb>
      <div className={styles.container}>
        <Form form={form} className={styles.formContent} style={{marginBottom:16}} onFinish={onFinishEvent}>
          <Space>
            <Form.Item name='keyWord' label="手机号/姓名" style={{margin:0}}>
              <Input placeholder='手机号/姓名' style={{width:200}}/>
            </Form.Item>
            <Form.Item style={{margin:0}}>
              <Button type='primary' onClick={()=>{form.submit()}}>查询</Button>
            </Form.Item>
          </Space>
          <Space>
              {
                exportLoading?<Button type='primary' loading>导出中...</Button>:<Button type='primary' onClick={exportEvent}>导出</Button>
              }
          </Space>
        </Form>
        <Table dataSource={dataSource}
          columns={columnsData} bordered
          scroll={{x:1200}}
          rowKey="id"
          pagination={{
            total:callData.totalRows,
            pageSize:callData.pageSize,
            onChange:pagesChangeEvent,
            showSizeChanger:false,
            showTotal:(total)=><span>总共{total}条数据</span>,
          }}
        />
      </div>
      <a ref={aEle} href='' download="" id="downloadBtn"/>
    </div>
  );
}

export default CallList;
