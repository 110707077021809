import React, { useEffect, useState } from 'react';
// import useSWR from 'swr';
import {useNavigate} from 'react-router-dom';
import {Button, Form, Input} from 'antd';
import {API,postFetch} from '../../axios/api';
import {utils} from '../../util/util';
import styles from './style.module.css';
// function useLogin(){
//   const {data,error} = useSWR(['/auth/login',{account:'admin',password:'123456'}],()=>postFetch('/auth/login',{account:'admin',password:'123456'}).then(res=>res.data));
//   console.log("data=",data)
// }

function Login() {
  // const [loginFetchState,setLoginFetchState] = useState(false);
  let navigate = useNavigate();
  const [form] = Form.useForm();
  // const {data,error} = useSWR(loginFetchState?[API.authLogin,{account:"admin",password:'123456'}]:null,postFetch);

  const onFinish = (e)=>{
    postFetch(API.authLogin,{...e}).then((res)=>{
      if(res){
        utils.SetCookie({token:res.data.data.token,path:'/','Max-Age':(23*60*60)});
        utils.setStorage('userInfo',res.data.data.userInfo);
        utils.setStorage('contactsId',res.data.data.userInfo.id);
        navigate('/layout/address-list')
      }
    })
  }
  return (
    <>
      <div className={styles.container} style={{backgroundImage: "url('assets/login_bg.png')"}}>
        <div className={styles.loginContainer} style={{backgroundImage: "url('assets/bg.png')"}}>
            <Form form={form} className={styles.information} labelCol={{ span: 5 }} wrapperCol={{ span: 19 }} onFinish={onFinish}>
              <Form.Item name='account' label='账户' rules={[{required:true,message:"账户不能为空"}]}>
                <Input placeholder='请输入账户' ></Input>
              </Form.Item>
              <Form.Item name='password' label='密码' rules={[{required:true,message:"密码不能为空"}]}>
                <Input placeholder='请输入密码'></Input>
              </Form.Item>
              {/* <Form.Item name='' label='验证码' rules={[{required:true,message:""}]}>
                <Input style={{width:180}} placeholder='请输入验证码'></Input><img src="" style={{width:100,height:32,float:'right'}}/>
              </Form.Item> */}
              <div>
                <Button type="primary" style={{width:'100%'}} onClick={()=>{form.submit()}}>登录</Button>
              </div>
            </Form>
        </div>
      </div>
    </>
  );
}

export default Login;
