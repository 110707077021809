import React, { useEffect, useState } from 'react';
import {Form, Table,Input, Button, Space,Breadcrumb,Select, message,Modal} from 'antd';
import {useNavigate} from 'react-router-dom';
import {ExclamationCircleOutlined} from '@ant-design/icons';
import {API,getFetch,postFetch} from '../../axios/api';
import styles from './style.module.css';
import Treelide from './components/TreeSlide/index';
import {utils,validator} from "../../util/util";

const {Option} = Select;
const roleArr = [
  {id:'user',name:'普通用户',level:0},
  {id:'admin',name:'管理员',level:2},
  {id:'teamLeader',name:'小组主管',level:1},
  // {id:'superAdmin',name:'超级管理员'},
]

function MemberManagement() {
  const [params,setParams] = useState({pageNum:1,pageSize:10});
  const [userData,setUserData] = useState({pageNum:1,pageSize:10,totalRows:0});
  const [userInfo,setUserInfo] = useState({});
  const [userList,setUserList] = useState([]);
  const [selectTeam,setSelectTeam] = useState([]);
  const [createVidible,setCreateVisible] = useState(false);
  const [editVidible,setEditVidible] = useState(false);
  const navigate = useNavigate();
  // const [form] = Form.useForm(null);
  const [editForm] = Form.useForm(null);
  const [createForm] = Form.useForm(null);
  const [roleState,setRoleState] = useState(false);
  const [roleCreate,setRoleCreate] = useState(false);

  const columnsData = [
    {title: '登录账号',dataIndex:'account',width:120,},
    {title: '姓名',dataIndex:'nick',width:120},
    {title: '角色',dataIndex:'roleName',width:120},
    {title: '所属小组',dataIndex:'teamName',width:120},
    {title: '创建时间',dataIndex:'createTime',width:120},
    {title: '更新时间',dataIndex:'updateTime',width:120},
    {title: '操作',dataIndex:'',width:200,render:(text,record,index)=>{
        return(<Space>
          <Button onClick={()=>{editEvent(record)}} type='primary'>编辑</Button>
          <Button danger type='primary' onClick={()=>{deleteEvent(record)}}>删除</Button>
        </Space>)
    }},
  ];
  useEffect(()=>{
    getUserListEvent()
    getTeamAllList()
    let userInfo = {};
    try {
      userInfo = JSON.parse(utils.getStorage('userInfo'));
      setUserInfo(userInfo)
    } catch (error) {
      navigate('/')
    }
    if(userInfo?.roleCode === 'admin'){
        setRoleState(true)
    }
    if(userInfo?.roleCode !== 'user'){
      setRoleCreate(true)
    }
  },[])
  /** 获取用户列表 */
  const getUserListEvent = (val)=>{
    const param = {...params,...val}
    getFetch(API.userList,{...param}).then((res)=>{
      if(res){
        setUserData(res.data.data);
        setUserList(res.data.data.records);
      }
    })
  }
  const editEvent = (records)=>{
    setEditVidible(true)
    editForm.setFieldsValue({...records})
  }
  const deleteEvent =(record)=>{
    Modal.confirm({
      title: `删除${record.account}`,
      icon: <ExclamationCircleOutlined />,
      content: `确认删除${record.account}账户吗？`,
      okText: '确认',
      cancelText: '取消',
      onOk:()=>{
        postFetch(API.userDelete,{id:record.id}).then((res)=>{
          if(res){
            if(res.data.resultCode === 0){
              message.success('删除成功')
              getUserListEvent();
            }else{
              message.error(res.data.resultMsg)
            }
          }else{
            message.error('删除失败')
          }
        })
      }
    });
  }
  const getTeamAllList = ()=>{
    getFetch(API.teamAllList,{}).then((res)=>{
        if(res){
          setSelectTeam([...res.data.data])
        }
    })
  }
  const createEvent = ()=>{
    createForm.setFieldsValue({teamId:userInfo.teamId})
    setCreateVisible(true)
  }
  /** 新建用户 */
  const okCreateEvent = (e)=>{
    postFetch(API.userCreate,{...e}).then((res)=>{
      if(res){
        if(res.data.resultCode === 0){
          message.success('保存成功！')
          createForm.resetFields();
          setCreateVisible(false)
          getUserListEvent();
        }else{
          message.error(res.data.resultMsg)
        }
      }else{
        message.error('保存失败')
      }
    })
  }
  /** 编辑用户 */
  const okEditEvent = (e)=>{
    if(e.password === ''){
      delete e.password;
    }
    postFetch(API.userUpdate,{...e}).then((res)=>{
      if(res){
        if(res.data.resultCode === 0){
          message.success("编辑成功！");
          getUserListEvent({pageNum:0});
          editForm.resetFields();
          setEditVidible(false)
        }else{
          message.error(res.data.resultMsg)
        }
      }else{
        message.error('编辑失败')
      }
    })
  }
  const pagesChangeEvent = (e)=>{
    setParams({...params,pageNum:e})
    getUserListEvent({pageNum:e})
  }
  const menuClickEvent = (id)=>{
    if(params?.teamId === id){
      return;
    }else{
      setParams({...params,pageNum:1,teamId:id})
    }
    getUserListEvent({teamId:id});
  }
  return (
    <div>
        <Breadcrumb>
          <Breadcrumb.Item className={styles.breadCont}>用户管理</Breadcrumb.Item>
        </Breadcrumb>
        <div className={styles.container}>
          <div className={styles.groupList}>
            <Treelide getTeamAllList={getTeamAllList} updateParams={menuClickEvent} getUserListEvent={getUserListEvent}></Treelide>
          </div>
          <div className={styles.dataContain}>
            {
              roleCreate && <div className={styles.formContent}>
                              <Button type='primary' onClick={createEvent}>新建</Button>
                          </div>
            }
            <Table dataSource={[...userList]} 
              columns={columnsData} bordered
              width='100%'
              scroll={{x:1200}}
              rowKey="id"
              pagination={{
                total:userData.totalRows,
                pageSize:userData.pageSize,
                current:params.pageNum,
                onChange:pagesChangeEvent,
                showSizeChanger:false,
                showTotal:(total)=><span>总共{total}条数据</span>,
              }}
            />
          </div>
        </div>
        <Modal title="新增用户" visible={createVidible} cancelText="取消" okText="保存" onOk={()=>{createForm.submit()}} onCancel={()=>{setCreateVisible(false);createForm.resetFields()}}>
          <Form labelCol={{ span: 4 }} wrapperCol={{ span: 18 }} form={createForm} onFinish={okCreateEvent}>
            <Form.Item name="id" label="id" style={{display:'none'}}>
              <Input></Input>
            </Form.Item>
            <Form.Item name="account" label="登录账号" rules={[{required:true,message:'登录账号不能为空'}]}>
              <Input></Input>
            </Form.Item>
            <Form.Item name="nick" label="昵称" rules={[{required:true,message:'昵称不能为空'}]}>
              <Input></Input>
            </Form.Item>
            <Form.Item name="password" label="密码" rules={[{required:true,message:'密码不能为空'},{validator:validator.passwordValidator}]}>
              <Input></Input>
            </Form.Item>
            <Form.Item name="passwords" label="确认密码" rules={[{required:true,message:'确认密码不能为空'},{validator:(_,value)=>{
                                let values = createForm.getFieldValue('password');
                                if(values === value){
                                    return Promise.resolve();
                                }else{
                                    return Promise.reject(new Error('两次输入的密码不一致'));
                                }
                            }}]}>
              <Input></Input>
            </Form.Item>
            <Form.Item name="role" label="角色" initialValue={'user'} rules={[{required:true,message:'角色不能为空'}]}>
              <Select>
                {
                  roleArr.map((item,index)=>{
                    return <Option key={`${item.id}-${index}`} value={item.id} disabled={item.level>1 && userInfo.role !== 'admin'}>{item.name}</Option>
                  })
                }
              </Select>
            </Form.Item>
            <Form.Item name="teamId" label="所属小组">
              <Select disabled={userInfo.role !== 'admin'}>
                {
                  selectTeam.map((item,index)=>{
                    return <Option key={`${item.id}-${index}`} value={item.id}>{item.name}</Option>
                  })
                }
              </Select>
            </Form.Item>
          </Form>
        </Modal>
        <Modal title="编辑用户" visible={editVidible} cancelText="取消" okText="保存" onOk={()=>{editForm.submit()}} onCancel={()=>{setEditVidible(false);editForm.resetFields()}}>
          <Form labelCol={{ span: 4 }} wrapperCol={{ span: 18 }} form={editForm} onFinish={okEditEvent}>
            <Form.Item name="id" label="id" style={{display:'none'}}>
              <Input></Input>
            </Form.Item>
            <Form.Item name="password" label="密码" rules={[{validator:(_,value)=>{
              if(value === undefined || value === ''){
                return Promise.resolve()
              }else{
                return validator.passwordValidator(_,value)
              }
            }}]}>
              <Input></Input>
            </Form.Item>
            <Form.Item name="role" label="角色" rules={[{required:true,message:"角色不能为空"}]}>
              <Select>
                {
                  roleArr.map((item,index)=>{
                    return <Option key={`${item.id}-${index}`} value={item.id}>{item.name}</Option>
                  })
                }
              </Select>
            </Form.Item>
            <Form.Item name="teamId" label="所属小组">
              <Select>
                {
                  selectTeam.map((item,index)=>{
                    return <Option key={`${item.id}-${index}`} value={item.id}>{item.name} </Option>
                  })
                }
              </Select>
            </Form.Item>
          </Form>
        </Modal>
    </div>
  );
}

export default MemberManagement;
