
import {useEffect,useState,useRef, useCallback} from 'react';
import {Avatar,Modal,Row,Col,Statistic,Button, Space, Input,Form, message} from 'antd';
import {useSetRecoilState,useRecoilState, useRecoilValue} from "recoil";
import {utils,validator} from '../../util/util';
import {UserOutlined} from '@ant-design/icons';
import styles from './style.module.css';
import {useNavigate} from 'react-router-dom';
import JsSIP from "jssip";
import { API,postFetch } from '../../axios/api';
import DragPhone from '../DragPhone';
import {PlayInformation,PlayState} from "../../store/index";

let defaultUser = {
  account: "",
  email: "",
  id: 1,
  mobile: "",
  nick: "",
  role: "",
  roleCode: "",
  teamId: "",
}
const Headers = ()=>{
  const [userInfo,setUserInfo] = useState({...defaultUser});
  const [visible,setVisible] = useState(false);
  const [phoneVisible,setPhoneVisible] = useState('hidden');
  const navigate = useNavigate();
  const [updateModal,setUpdateModal] = useState(false)
  const [form] = Form.useForm(null); 
  const [formPlay] = Form.useForm(null); 
  const UARef = useRef(null);
  /** 呼叫信息 */
  const CallInfo = useRef({callState:false,callId:"",callConfirmed:false}); 

  const [fsCallUserId,setFsCallUserId] = useState(1010 + Math.floor(10 * Math.random()));//需要改成 1011~1020 随机用户。
  const [linkState,setLinkState] = useState(1);
  const setPlayState= useSetRecoilState(PlayState);
  const [playInformation,setPlayInformation] = useRecoilState(PlayInformation);
  const playState = useRecoilValue(PlayState);
  
  /** 
   * 提交通话状态结果
   * */
  const postCallResult = (s)=>{
    const params = {
      callId:CallInfo.current.callId,
      status:s
    }
    postFetch(API.addressBookCallResult,params).then((res)=>{
      if(res){
        
      }
    }).finally(()=>{
      CallInfo.current = {callState:false,callId:"",callConfirmed:false};
    })
  };
  
  const registrationFailedEvent = function(data){
    console.log("注册失败",data);
  };
  const acceptedEvent = function(data){
      console.log("接受呼叫时触发",data);
      if(data.originator === 'remote'){
          console.log("传出信息");
      }else{
          console.log("传入信息");
      }
  }
  const confirmedEvent = function(data){
      console.log("确认呼叫时触发",data);
      if(data.originator === 'remote'){
          console.log("传出信息");
      }else{
          console.log("传入信息");
      }
  }
  const failedEvent = function(data){
    console.log("呼叫失败")
  }
  const eventHandlers = useRef({
    'progress': (e)=>{
      // setLinkState(2)
      console.log('call 呼叫进行中...');
    },
    'failed': (e)=>{
      setLinkState(4)
      postCallResult("FAIL");
      console.log('call 呼叫失败 ', e);
    },
    'ended':(e)=>{
      setLinkState(5)
      console.log('call 呼叫结束 : ', e);
    },
    'confirmed':(e)=>{
      setLinkState(3)
      postCallResult('CHATTING')
      console.log('call 呼叫确认');
    }
  });
  /**
   * 进行呼叫
   * */
  const callEvent = useCallback(()=>{
    if(playInformation.callId && playInformation.mobile){
      const sip_phone_call = `sip:${playInformation.mobile}@${process.env.REACT_APP_WSS}`;//`sip:999${'15721409084'}@${process.env.REACT_APP_WSS}`;////sip:999${callMobile.mobile}@192.168.1.61:5060`;
      setPhoneVisible('visible')
      CallInfo.current.callId = playInformation.callId;
      const options = {
        'eventHandlers':eventHandlers.current,
        'mediaConstraints':{ 'audio': true, 'video': false},
        'sessionTimersExpires':200,
        'extraHeaders':[`X-custom-call-id:${playInformation.callId}`],
        'pcConfig':{
            'iceServers': [
              {'urls': ['stun:stun.voipbuster.com']}
            ]
        }
      };
      CallInfo.current.state = true;
      UARef.current.call(sip_phone_call,options);
    }
  },[playInformation.callId, playInformation.mobile]);
  /**
   * SIP 实例注册成功
   * */
  const registeredEvent = useCallback((info)=>{
    message.success('设置成功');
    setTimeout(()=>{
      callEvent()
    },100)
    console.log("注册成功",info);
  },[callEvent]);
  /** 结束对话 */
  const callStopEvent = useCallback(()=>{
    //关闭呼叫对象
    UARef.current.terminateSessions();
    UARef.current.unregister({all: true});
    setPhoneVisible('hidden');
    setLinkState(1);
    //初始化 呼叫信息
    setPlayInformation(()=>{return{}});
  },[setPlayInformation])
  /**
   * 注册通话sip
   * */
  const initEvent = useCallback(()=>{
    navigator.getUserMedia = navigator.getUserMedia || navigator.webkitGetUserMedia || navigator.mozGetUserMedia || navigator.msGetUserMedia;
    if(navigator.getUserMedia){
      console.log('支持麦克风权限')
    }else{
      console.log('不支持麦克风权限') 
      return;
    }
    navigator.getUserMedia({audio:true,video:false},function(sus){
      console.log("开启麦克风权限")
      let initSipInformation={
          "url": `sip:${fsCallUserId}@${process.env.REACT_APP_WSS}`,
          "password":"2021",
          "wss":`wss://${process.env.REACT_APP_WSS}:10006`,
        },
        socket = new JsSIP.WebSocketInterface(initSipInformation.wss),
        configuration = {
            sockets  : [ socket ],
            uri      : `${initSipInformation.url};transport=wss`,
            password : initSipInformation.password,
            stun_servers:"stun:stun.voipbuster.com",
            // register_expires:10
        },
        ua = new JsSIP.UA(configuration);
      UARef.current = ua;
      /**
       * 注册成功之后
       * */
      ua.on('registered',registeredEvent);
      /**
       * 注册失败
       * */
      ua.on('registrationFailed',registrationFailedEvent);
      /**
       * 建立会话
       * */
      ua.on('newRTCSession',function(data){
        console.log("新的Rtc会话",data);
        if(data.originator === 'remote'){
            console.log("输入会话内容");
            data.session.answer({
                mediaConstraints:{audio:true}
            })
        }else{
            console.log("传出会话内容");
            data.session.on('connecting',function(data){
              setLinkState(2)
              console.log(`会话请求内容 ${data.request}`);
            })
        }
        //接受呼叫
        data.session.on('accepted',acceptedEvent);
        //当呼叫被确认的时候
        data.session.on('confirmed',confirmedEvent);
        //建立的呼叫结束时触发
        data.session.on('ended',function(data){
          console.log("呼叫结束时触发");
          if(data.originator === "local"){
              console.log("传出端结束");
          }else if(data.originator === "remote") {
              console.log("对方结束挂断");
          }else if(data.originator === "system"){
              console.log("系统结束信息");
          }
          callStopEvent();
          console.log("结束原因：",data.cause);
      });
        //链接失败的时候
        data.session.on('failed',failedEvent);
        //添加监听的音频
        data.session.connection.addEventListener('addstream',function(ev){
            console.log("音频数据流，传输中...",ev.stream);
            const audioElement = document.getElementById('audio');
            audioElement.srcObject  = ev.stream;
            audioElement.play();
        })
      })
      /**
       * 新消息传输
       * */
      ua.on('newMessage',function(data){
          console.log("新消息",data);
          if(data.originator === 'remote'){
              console.log("传入新消息");
          }else if(data.originator === 'local'){
              console.log('传出新消息');
          }
      })
      ua.start();
    },function(){
      console.log("不开启麦克风权限")
      message.error("你还未开启浏览器的麦克风权限")
      return;
    })
  },[callStopEvent, fsCallUserId, registeredEvent]);
  
  
  /** 退出登录 */
  const okEvent = ()=>{
    window.localStorage.clear();
    navigate('/');
  }
  /** 拨号事件 */
  const playFinishEvent = (e)=>{
    Modal.confirm({
      title:"拨号提示",
      okText:"确定",
      cancelText:"取消",
      content:`确定拨打手机号为'${e.mobile}'的用户吗？`,
      onCancel:()=>{},
      onOk:()=>{
        postFetch(API.addressBookCall,{mobile:e.mobile}).then((res)=>{
          if(res){
            if(res.data.resultCode === 0){
              // 更信息呼叫信息，进行触发监听的呼叫事件
              setPlayInformation({...res.data.data})
            }else{
              message.error(res.data.resultMsg)
            }
          }else{
            message.error("拨号失败")
          }
        })
      }
    })
  }
  const onFinishEvent = (e)=>{
    postFetch(API.authUpdatePassword,{...e}).then((res)=>{
      if(res){
        if(res.data.resultCode === 0){
          form.resetFields()
          message.success('修改成功')
          setUpdateModal(false)
          utils.SetCookie({token:res.data.data.token,path:'/','Max-Age':(23*60*60)})
        }else{
          message.error(res.data.resultMsg)
        }
      }
    })
  }
  /**
   * 呼叫信息存在就进行呼叫 
   * */
  useEffect(()=>{
    if(playInformation.mobile){
      initEvent();
    }
  },[initEvent, playInformation])
  /** 
   * 更新状态 
   * */
  useEffect(()=>{
    setPlayState(linkState)
  },[linkState,setPlayState])
  /** 
   * 初始化
   * */
  useEffect(()=>{
    let userInformation = {};
    try {
      userInformation = JSON.parse(utils.getStorage('userInfo'));
    } catch (error) {
      navigate('/')
    }
    setUserInfo(userInformation);
    //刷新处理事件
    function beforeunloadEvent(){
      if(UARef.current && UARef.current.terminateSessions){
        UARef.current.terminateSessions();
        UARef.current.unregister({all: true});
      }
      window.removeEventListener('beforeunload',beforeunloadEvent)
    }
    window.addEventListener('beforeunload',beforeunloadEvent)
    return()=>{
      callStopEvent()
      window.removeEventListener('beforeunload',beforeunloadEvent)
    }
  },[])
  return (<div className={styles.headerRight}>
      <Form style={{'display':'inline-block',marginRight:12}} onFinish={playFinishEvent} form={formPlay}>
        <Space>
          <Form.Item name='mobile' rules={[{validator:validator.phoneValidator}]}>
            <Input type="text" placeholder="输入手机号"/>
          </Form.Item>
          <Form.Item>
            <Button type='primary' disabled={!(playState===1)} onClick={()=>{formPlay.submit()}}>快速拨号</Button>
          </Form.Item>
        </Space>
      </Form>
      <div style={{cursor:'pointer',display:'inline-block'}} onClick={()=>{setVisible(true)}}>
        <Avatar icon={<UserOutlined />} size={32}/>
        <Button type="text"> {userInfo.nick}</Button>
      </div>
      <Modal
        mask={false}
        style={{position:'absolute',top:70,right:10 }}
        visible={visible}
        closable={false}
        // okText="退出"
        // cancelText="关闭"
        // onOk={okEvent}
        footer={null}
        onCancel={()=>{setVisible(false)}}
        width={360}>
        <Row style={{backgroundColor:'#f8f9fb',padding:8}}>
          <Col span={6}>
              <Avatar  style={{float:'right'}} size={64}/>
          </Col>
          <Col span={18}>
              <div style={{padding:'5px 15px'}}>{userInfo.nick}</div>
              <Space>
                <Button type='link' onClick={()=>{setUpdateModal(true);setVisible(false)}}>修改密码</Button>
                <Button type='link' onClick={okEvent}>退出登录</Button>
              </Space>
          </Col>
        </Row>
        <Statistic style={{padding:10}} valueStyle={{fontSize:16}} title="角色:" value={userInfo.roleName||'无'} />
        <Statistic style={{padding:10}} valueStyle={{fontSize:16}} title="所属小组:" value={userInfo.teamName||'无'} />
        {/* <Statistic style={{padding:10}} valueStyle={{fontSize:16}} title="手机号码:" formatter={(val)=>{return val||'无'}} value={userInfo.mobile} /> */}
      </Modal>
      <Modal visible={updateModal} 
        okText="提交" 
        cancelText="取消" 
        onOk={()=>{form.submit()}} 
        onCancel={()=>{form.resetFields();setUpdateModal(false);setVisible(false);}} 
        mask={false}
        title="修改密码"
        style={{position:'absolute',top:70,right:10 }}
        width={360}
        closable={false}>
        <Form form={form} onFinish={onFinishEvent} labelCol={{span:6}} wrapperCol={{span:15}}>
          <Form.Item name="password" label="旧密码" rules={[{required:true,message:"请输入旧密码"},{validator:validator.passwordValidator}]}>
            <Input placeholder='请输入旧密码'></Input>
          </Form.Item>
          <Form.Item name="newPassword" label="新密码" rules={[{required:true,message:"请输入新密码"},{validator:validator.passwordValidator}]}>
            <Input placeholder='请输入新密码'></Input>
          </Form.Item>
          <Form.Item name="ok" label="确认密码" rules={[{required:true,message:"请确认密码"},{validator:(_,value)=>{
            const p = form.getFieldValue('newPassword');
            if(p === value){
              return Promise.resolve()
            }else{
              return Promise.reject('两次输入的密码不一致')
            }
          }}]}>
            <Input placeholder='请确认密码'></Input>
          </Form.Item>
        </Form>
      </Modal>
      <audio id="audio"></audio>
      {
         <DragPhone visible={phoneVisible} visibleChange={setPhoneVisible} linkState={linkState} stopEvent={callStopEvent} callMobile={playInformation}/>
      }
  </div>);
}

export default Headers;
