
// interface cookieType{
//     [key:string]:string
// };
export const utils = {
    getStorage(name){
        let data = window.localStorage.getItem(name);
        if(data){
            return unescape(data);
        }else{
            return '';
        }
    },
    setStorage(name,data){
        let dataType = typeof data;
        if (dataType === 'object'){
            window.localStorage.setItem(name,escape(JSON.stringify(data)));
        }
        else if(['number','string','boolean'].indexOf(dataType)>=0){
            window.localStorage.setItem(name,escape(data));
        }
        else{
            alert("该类型不能用于本地存储");
        }
    },
    removeStorage(name){
        window.localStorage.removeItem(name);
    },
    getCookie(key){
        if(key === '') return;
        const cookieArr = document.cookie.split(';');
        const obj = {};
        for(let i = 0; i < cookieArr.length;i++){
            if(!cookieArr[i].includes('=')){
                continue;
            }
            let cookies = cookieArr[i].split("=");
            obj[`${cookies[0].trim()}`] = cookies[1].trim();
        }
        return obj[key];
    },
    SetCookie(obj){
        if(!Object.prototype.toString.call(obj).includes('Object')) return;
        let val = '';
        Object.keys(obj).forEach((item)=>{
            val+= `${item}=${obj[item]};`;
        })
        document.cookie = val;
    }
}
export const validator = {
    phoneValidator(_,value){
        const regExp = new RegExp(/^[1]([3-9])[0-9]{9}$/);
        if(regExp.test(value)){
            return Promise.resolve();
        }else{
            return Promise.reject('请输入正确的手机号');
        }
    },
    passwordValidator(_,value){
        if(value.length >= 6 && value.length <= 16 ){
            return Promise.resolve()
        }else{
            return Promise.reject('密码长度在6～16位之间')
        }
    }
};
export const fileType = {
    toExcel(res){
        const render = new FileReader();
        render.readAsDataURL(res.data);
        let a = document.createElement("a");
        render.onload = (e)=>{
            if(res.headers['content-disposition'] && res.headers['content-disposition'].includes("filename=")){
                let fileName = res.headers['content-disposition'].includes("UTF-8''") ? res.headers['content-disposition'].split("UTF-8''")[1].replace(/\"/g, "") : res.headers['content-disposition'].split("filename=")[1].replace(/\"/g, "")
                a.download= decodeURI(fileName);
            }else{
                a.download= `${(Math.random()*100000000).toFixed(0)}.xlsx`;
            }
            a.href=e.target.result;
            a.click()
        } 
    },
}