import {atom} from "recoil";
const PlayInformation = atom({
    key:'PlayInformation',
    default:{}
})
const PlayState = atom({
    key:'PlayState',
    default:1
})

export {PlayInformation,PlayState};