import { useRoutes } from "react-router-dom";
import Layout from "../pages/Layout";
import AddressList from "../pages/AddressList";
import CallList from "../pages/CallList";
import Login from "../pages/Login";
import MemberManagement from "../pages/MemberManagement";
// import {BrowserRouter} from "@types/react-router-dom";
export const routes = [
    {
        path:"/layout",
        element:<Layout/>,
        children:[{
            path:'address-list',
            title:'通讯录',
            element:<AddressList/>
        },{
            path:'call-list',
            title:'呼叫记录',
            element:<CallList/>
        },{
            path:'member-management',
            title:'成员管理',
            element:<MemberManagement/>
        }]
    },{
        path:'/',
        element:<Login/>
    }
];
export function Routes(){
    let element = useRoutes(routes)
    return element;
}