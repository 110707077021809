import {useState,useEffect} from "react";
import {Button, Menu, message,Modal,Form,Input, Space, Tooltip} from 'antd';
import {useNavigate} from 'react-router-dom';
import styles from './style.module.css';
import {PlusOutlined,ExclamationCircleOutlined,DeleteOutlined,EditOutlined} from '@ant-design/icons';
import {API,getFetch,postFetch} from '../../../../axios/api';
import {utils} from "../../../../util/util";

const TreeSlide = ({getTeamAllList,updateParams,getUserListEvent})=>{
    const [form] = Form.useForm();
    const navigate = useNavigate();
    const [teamArr,setTeamArr] = useState([{id:'120',name:'管理员'},{id:'110',name:'team组长'}]);
    const [teamType,setTeamType] = useState("新增");
    const [modalVisible,setModalVisible] = useState(false);
    const [roleState,setRoleState] = useState(false);
    const [selectedKeysArr,setSelectedKeysArr] = useState(['all-0'])
    useEffect(()=>{
        getTeamList();
        let userInfo = {};
        try {
            userInfo = JSON.parse(utils.getStorage('userInfo'));
        } catch (error) {
            navigate('/')
        }
        if(userInfo?.roleCode === 'admin'){
            setRoleState(true)
        }
    },[])
    
    const getTeamList = ()=>{
        getFetch(API.teamList,{}).then((res)=>{
            if(res){
                setTeamArr(res.data.data)
            }
        })
    }
    const teamDeleteEvent = (item)=>{
        Modal.confirm({
            title: `删除${item.name}`,
            icon: <ExclamationCircleOutlined />,
            content: `确定删除${item.name}小组吗？`,
            okText: '确认',
            cancelText: '取消',
            onOk:()=>{
              postFetch(API.teamDelete,{id:item.id}).then((res)=>{
                if(res){
                  if(res.data.resultCode === 0){
                    message.success('删除成功')
                    getTeamList()
                    getTeamAllList()
                  }else{
                    message.error(res.data.resultMsg)
                  }
                }else{
                  message.error('删除失败')
                }
              })
            }
          });
    }
    const onFinishEvent = (e)=>{
        if(teamType === '新增'){
            postFetch(API.teamCreate,{...e}).then((res)=>{
                if(res){
                    if(res.data.resultCode === 0){
                        message.success("新增成功")
                    }else{
                        message.error(res.data.resultMsg)
                    }
                }else{
                    message.error("新增失败")
                }
            }).finally(()=>{
                form.resetFields()
                setModalVisible(false);
                getTeamList()
                getTeamAllList()
            })
        }
        if(teamType === '编辑'){
            postFetch(API.teamUpdate,{...e}).then((res)=>{
                if(res){
                    if(res.data.resultCode === 0){
                        message.success("编辑成功")
                    }else{
                        message.error(res.data.resultMsg)
                    }
                }else{
                    message.error("编辑失败")
                }
            }).finally(()=>{
                form.resetFields()
                setModalVisible(false);
                getTeamList()
                getTeamAllList()
            })
        }
    }
    const editOption = (records)=>{
        setModalVisible(true)
        form.setFieldsValue({...records})
        setTeamType('编辑')
    }
    const addInput = ()=>{
        setModalVisible(true);
        setTeamType('新增')
    }
    const clickMenuEvent = (id,index)=>{
        if(id === null){
            setSelectedKeysArr([`all-0`])
        }else{
            setSelectedKeysArr([`${id}-${index}`])
        }
        updateParams(id)
    }
    return(<>
        <Menu selectedKeys={selectedKeysArr}>
            <Menu.Item className={styles.menuBtn} key="all-0">
                <div className={styles.menuItem}>
                    <span className={styles.textHidden} style={{width:120}} onClick={()=>{clickMenuEvent(null)}}>所有用户</span>
                </div>
            </Menu.Item>
            {
                teamArr.map((item,index)=>{
                    return <Menu.Item key={`${item.id}-${index}`} className={styles.menuBtn}>
                                <div className={styles.menuItem}>
                                    <Tooltip title={item.name}>
                                        <span className={styles.textHidden} style={{width:120}} onClick={()=>{clickMenuEvent(item.id,index)}}>{item.name}</span>
                                    </Tooltip>
                                    <Space className={styles.options}>{roleState && <DeleteOutlined onClick={()=>{teamDeleteEvent(item)}}/>}<EditOutlined onClick={()=>{editOption(item)}}/></Space>
                                </div>
                            </Menu.Item>
                })
            }
            {
                roleState && <Button type="primary" style={{width:'100%'}} onClick={addInput}>添加小组<PlusOutlined /></Button>
            }
        </Menu>
        <Modal visible={modalVisible} onOk={()=>{form.submit()}} onCancel={()=>{form.resetFields();setModalVisible(false)}} okText="确定" cancelText="取消" title={`${teamType}team`}>
            <Form form={form} onFinish={onFinishEvent}>
                {
                    teamType === '编辑' && <Form.Item name="id" style={{'display':'none'}}>
                        <Input></Input>
                    </Form.Item>
                }
                <Form.Item name="name" label="team 名字">
                    <Input maxLength={30}></Input>
                </Form.Item>
            </Form>
        </Modal>
    </>)
}
export default TreeSlide;