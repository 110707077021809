// import useSWR,{SWRConfiguration} from 'swr';
// import {AxiosResponse,AxiosError} from 'axios';
// import{loginAPI} from '../type/APIParams';
import Axios from "./index";
// const deleteFetch = (url,params)=>Axios.delete(url,{data:params});
const getFetch = (url,params)=>Axios.get(url,{params:{...params}});
const postFetch = (url,params)=>Axios.post(url,params);

const API = {
    /** 登陆 */
    authLogin:'/auth/login',
    /** 修改密码 */
    authUpdatePassword:'/auth/updatePassword',
    /** 通话记录 联系人导出 */
    callRecordExport:'/callRecord/export',
    /** 通话记录 分页查询联系人列表 */
    callRecordList:'/callRecord/list',
    /** 通讯录 拨打 */
    addressBookCall:'/addressBook/call',
    /** 通讯录 新建联系人 */
    addressBookCreate:'/addressBook/create',
    /** 通讯录 删除联系人 */
    addressBookDelete:'/addressBook/delete',
    /** 通讯录 导出模板 */
    addressBookExportTemplate:'/addressBook/exportTemplate',
    /** 通讯录 导入通讯录 */
    addressBookImport:'/addressBook/import',
    /** 通讯录 分页查询联系人列表 */
    addressBookList:'/addressBook/list',
    /** 通讯录 修改联系人 */
    addressBookUpdate:'/addressBook/update',
    /** 小组 查询所有小组 */
    teamAllList:'/team/allList',
    /** 查询小组list(列表) */
    teamList:'/team/list',
    /** 小组 创建小组 */
    teamCreate:'/team/create',
    /** 小组 删除小组 */
    teamDelete:'/team/delete',
    /** 小组 修改小组 */
    teamUpdate:'/team/update',
    /** 新建用户 */
    userCreate:'/user/create',
    /** 删除用户 */
    userDelete:'/user/delete',
    /** 分页查询用户列表 */
    userList:'/user/list',
    /** 修改用户 */
    userUpdate:'/user/update',
    /** check */
    healthCheck:'/health/check',
    /**  */
    addressBookCallResult:"/addressBook/callResult",
};
export {
    API,
    postFetch,
    getFetch,
}