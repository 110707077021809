import axios from 'axios';
import {utils} from '../util/util';
import { message } from 'antd';
const Axios = axios.create({
  baseURL: process.env.REACT_APP_DOMAIN_API//'https://sth-api.leyanbot.com/smartcall-backend'
});

Axios.defaults.timeout = 30000;
Axios.interceptors.request.use(
  (config) => {
    /** 登录接口 */
    if(config.url === '/auth/login'){
      return config;
    }
    /** token 是否失效 */
    if(utils.getCookie('token')){
      const token = utils.getCookie('token');
      config.headers = {
        'Authorization':'Bearer ' + token
      };
      return config;
    }else if(window.location.hash !== '' && window.location.hash !== '#/'){
      window.location.href=('/');
    }
  }, 
  error => { 
    return Promise.reject(error)
  })
const resultCodeArr = [100,101,401,4001,4002,4003,4004,4005];
// 响应拦截器
Axios.interceptors.response.use((response) => {
    if (response && response.status === 200) {
      console.log(response)
      switch (response.data.resultCode) {
        case 100:
          message.error('登录过期')
          break;
        case 101:
          message.error('登录信息不存在')
          break;
        case 110:
          message.error('权限不足')
          break;
        case 300:
          message.error('表单验证失败')
          break; 
        case 401:
          message.error('未登录')
          break;
        case 403:
          message.error('无权限')
          break;
        case 600:
          message.error('请求过于频繁')
          break;
        case 1:
          message.error(response.data.displayMsg ? response.data.displayMsg :'请稍后重试')
          break;
        case 4001:
          message.error('请先登录')
          break;
        case 4002:
          message.error('token解析失败')
          break;
        case 4003:
          message.error('用户不存在')
          break;
        case 4004:
          message.error('请重新登陆')
          break;
        case 4005:
          message.error('token验证失败,请重新登录')
          break;
        case 4006:
          message.error(response.data.resultMsg)
          break;
        default:
          return response
      }
      if(resultCodeArr.includes(response.data.resultCode) && !(window.location.hash === '') && !(window.location.hash === '#/')){
        setTimeout(()=>{
          window.location.href=('/')
        },1000)
      }
    }else { 
      message.error('请稍后重试')
    }
  },
  error =>{
    message.error('请求超时，请稍后重试')
    return Promise.reject(error)
  }
)
export default Axios
  