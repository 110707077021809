import {useState, useEffect} from "react";
import {Table,Modal} from 'antd';
import {API,getFetch} from '../../../../axios/api';
import ReactAudioPlayer from 'react-audio-player';

const CallList = (props)=>{
    const [callParam,setCallParam] = useState({pageNum:0,pageSize:15})
    const [dataSource,setDataSource] = useState([]);
    const [callData,setCallData] = useState({totalRows:0,pageNum:0,pageSize:15})
    const columnsData = [
        {title: '呼叫ID',dataIndex:'id',width:100},
        {title: '手机号',dataIndex:'mobile',width:120},
        {title: '呼叫人',dataIndex:'callOutUserName',width:120},
        {title: '呼叫时间',dataIndex:'callTime',width:120},
        {title: '呼叫结果',dataIndex:'status',width:120},
        {title: '音频',dataIndex:'audioUrl',width:200,render:(text)=>{
            return(<div key={text}><ReactAudioPlayer
                src={text}
                autoPlay={false}
                controls={true}
                style={{height:24,width:'130'}}/></div>)
        }},
    ];
    useEffect(()=>{
        if(props.callListVisible){
            setCallParam({...callParam,contactsId:props.contactsId})
            getCallList({contactsId:props.contactsId})
        }
    },[props.callListVisible])
    const getCallList = (params)=>{
        const param = {...callParam,...params}
        getFetch(API.callRecordList,param).then((res)=>{
            if(res){
                setDataSource([...res.data.data.records])
                setCallData({
                    pageSize:res.data.data.pageSize,
                    pageNum:res.data.data.pageNum,
                    totalRows:res.data.data.totalRows,
                })
            }
        })
    }
    const pagesChangeEvent = (page)=>{
        getCallList({pageNum:page});
    }
    const closeEvent = ()=>{
        setDataSource([])
        setCallData({
            pageSize:15,
            pageNum:0,
            totalRows:0,
        })
        setTimeout(()=>{
            props.setCallListVisible(false)
        },0)
    }
    return(<>
        <Modal title="呼叫记录" width={1000} 
            visible={props.callListVisible} 
            cancelText="取消" 
            okText="保存" 
            footer={null}
            onCancel={closeEvent}>
            <Table dataSource={dataSource} 
                columns={columnsData}
                bordered
                rowKey="id"
                scroll={{y:500,x:'auto'}}
                pagination={{
                    total:callData.totalRows,
                    pageSize:callData.pageSize,
                    onChange:pagesChangeEvent,
                    showSizeChanger:false,
                    showTotal:(total)=><span>总共{total}条数据</span>,
                }}
            />
        </Modal>
    </>)
}
export default CallList;