import {useCallback, useEffect, useRef, useState} from 'react';
import Style from './style.module.css';
import {Button} from "antd";
import {HolderOutlined,PhoneOutlined,FullscreenExitOutlined} from '@ant-design/icons';

const linkStateVal = {
    1:'开始打电话...',
    2:'呼叫中...',
    3:'通话中...',
    4:'呼叫失败',
    5:'通话结束'
};
let eve = null;
let miniEve = null;
const DragPhone = ({visible,visibleChange,linkState,stopEvent,callMobile})=>{
    const [PX,setPX] = useState('calc(100vw / 2 - 0)'),
        [PY,setPY] = useState('calc(100hw / 2 - 400)'),
        [miniPX,setMiniPX] = useState('calc(100% / 2 - 200)'),
        [miniPY,setMiniPY] = useState('calc(100% / 2 - 100)'),
        [miniVisible,setMiniVisible] = useState('hidden'),
        [lines,setLines] = useState([]),
        [timerLength,setTimerLength] = useState(0),
        [timerObj,setTimerObj] = useState(null),
        phoneRef = useRef(null),
        miniPhoneRef = useRef(null);
    /**
     * 拖拽移动
     * */
    const mouseDownEvent = useCallback((e)=>{
        let box = phoneRef.current.getBoundingClientRect();
        let box_l = { //鼠标相对box的位置
            x:e.pageX - box.x,
            y:e.pageY - box.y
        };
        document.querySelector('#phoneContentLayer').className = "bodyLayer";
        setPX(box.x);
        setPY(box.y);
        eve = function(e){
            if((e.pageX-box_l.x) < 0){
                setPX(0);
            }else if((window.innerWidth - 375) - (e.pageX-box_l.x) < 0){
                setPX(window.innerWidth - 375);
            }else{
                setPX(e.pageX-box_l.x);
            }

            if((e.pageY-box_l.y) < 0){
                setPY(0);
            }else if((window.innerHeight - 667) - (e.pageY-box_l.y) < 0){
                setPY(window.innerHeight - 667);
            }else{
                setPY(e.pageY-box_l.y);
            }
        }
        document.onmousemove = eve;
        document.onmouseup = function(){
            document.querySelector('#phoneContentLayer').className = "";
            document.onmousemove = function(){}
        }
    },[]);
    /**
     * 缩略图拖拽移动
     * */
    const mouseDownMiniEvent = useCallback((e)=>{
        let box = miniPhoneRef.current.getBoundingClientRect();
        let box_l = { //鼠标相对box的位置
            x:e.pageX - box.x,
            y:e.pageY - box.y
        };
        setMiniPX(box.x);
        setMiniPY(box.y);
        miniEve = function(e){
            if((e.pageX-box_l.x)<0){
                setMiniPX(0);
            }else if((window.innerWidth - 380) - (e.pageX-box_l.x) < 0){
                setMiniPX(window.innerWidth - 380);
            }else{
                setMiniPX(e.pageX-box_l.x);
            }

            if((e.pageY-box_l.y)<0){
                setMiniPY(0);
            }else if((window.innerHeight - 60) - (e.pageY-box_l.y) < 0){
                setMiniPY(window.innerHeight - 60);
            }else{
                setMiniPY(e.pageY-box_l.y);
            }
        }
        document.onmousemove = miniEve;
        document.onmouseup = function(){
            document.querySelector('#phoneContentLayer').className = "";
            document.onmousemove = function(){}
        }
        // window.addEventListener('mousemove',miniEve,false);
    },[]);
    /**
     * 取消拖拽监听
     * */
    // const mouseUpEvent = ()=>{
        
    // };
    /**
     * 取消拖拽监听
     * */
    // const mouseUpMiniEvent = ()=>{
    //     document.querySelector('#phoneContentLayer').className = "";
    //     window.removeEventListener('mousemove',miniEve,false);
    // };
    /**
     * 计时通话时长
     * */
    const timerEvent = useCallback(()=>{
        let t = 1;
        const timerObject = setInterval(()=>{
            setTimerLength(t);
            t++;
        },1000)
        setTimerObj(timerObject)
    },[]);
    /**
     * 秒转化成时间
     * */
    const mToTime = (timer)=>{
        const h = (timer/3600).toFixed(0) <= 9?`0${(timer/3600).toFixed(0)}`:(timer/3600).toFixed(0),
            m = (timer%3600/60).toFixed(0) <= 9?`0${(timer%3600/60).toFixed(0)}`:(timer%3600/60).toFixed(0),
            s = timer%60 <= 9 ? `0${timer%60}`:timer%60;
        return `${h}:${m}:${s}`
    }
    useEffect(()=>{
        if(linkState === 2){
            timerEvent()
        }
        if(linkState === 5 || linkState === 4){
            closeBtnEvent()
        }
    },[linkState])
    /**
     * 确定设置信息
     * */
    useEffect(()=>{
        const lines = (function(){
            let arr = [];
              for(let i = 0; i < 120; i++){
                  arr.push({
                      time:`${-0.05*i}s`
                  })
              }
              return arr;
          })()
          setLines(lines)
    },[])
    /** 
     * 关闭
     */
    const closeBtnEvent = ()=>{
        //取消拖拽
        // mouseUpEvent();
        // mouseUpMiniEvent();

        clearInterval(timerObj);
        setTimerObj(null);
        setTimerLength(0);
        visibleChange('hidden');
        setMiniVisible('hidden');
        stopEvent()
    }
    return(<div>
        <div id="phoneContentLayer"></div>
        <div className={Style.phoneContent}
            ref={phoneRef}
            onMouseDown={mouseDownEvent}
            // onMouseUp={mouseUpEvent}
            style={{top:`${PY}px`,left:`${PX}px`,visibility:visible}}
        >
            <section className={Style.phoneSystemInfo}></section>
            <section className={Style.scaleBtn}>
                {/*<FullscreenOutlined />*/}
                <FullscreenExitOutlined onClick={()=>{
                    setMiniVisible('visible');
                    visibleChange('hidden');
                }}/>
            </section>
            <section className={Style.phoneText}>
                <h3>{callMobile?.name || `${callMobile?.mobile}`.replace(/^(9990)|^(999)/,'')}</h3>
                <h4>{callMobile?.remark}</h4>
                <div className={Style.phoneStatus}>{linkStateVal[linkState]}</div>
            </section>
            <section className={Style.phoneOptionsContent}>
                <div className={Style.linesContent} style={{width:'100%',height:'26px',backgroundColor:'transparent'}}>
                    {
                        lines.map((item,index)=><div key={`${item}-${index}`} className={Style.lineAnimation} style={{animationDelay:item.time}}></div>)
                    }
                </div>
                <div className={Style.phoneOption}>
                    {/* <AudioMutedOutlined className={Style.phoneBtnItem}/>
                    <SoundOutlined className={Style.phoneBtnItem}/>
                    <HolderOutlined className={Style.phoneBtnItem}/> */}
                    {mToTime(timerLength)}
                </div>
                <div className={Style.phoneCallBtn}>
                    <Button type="primary" shape="circle" size='large' danger icon={<PhoneOutlined />} onClick={closeBtnEvent}/>
                </div>
            </section>
        </div>
        <section className={Style.phoneMini}
                 style={{visibility:miniVisible,top:`${miniPY}px`,left:`${miniPX}px`}}
                 onMouseDown={mouseDownMiniEvent}
                //  onMouseUp={mouseUpMiniEvent}
                ref={miniPhoneRef}>
            <div className={Style.phoneMiniState}>
                <div><PhoneOutlined className={Style.phoneIcon}/>{linkStateVal[linkState]}</div>
                <div className={Style.userSelect}>{`${callMobile?.mobile}`.replace(/^(9990)|^(999)/,'')}</div>
            </div>
            <div className={Style.phoneMiniBtnList}>
                {/* <PauseCircleOutlined className={Style.phoneMiniBtn}/> */}
                {/* <SoundOutlined className={Style.phoneMiniBtn}/> */}
                <HolderOutlined className={Style.phoneMiniBtn} onClick={()=>{
                    setMiniVisible('hidden');
                    visibleChange('visible');
                }}/>
                <Button type="primary" shape="circle" size='large' danger icon={<PhoneOutlined />} onClick={closeBtnEvent}/>
            </div>
        </section>
    </div>)
}
export default DragPhone;