import {Form, Table,Input, Button, Space,Modal,Breadcrumb, message,Upload} from 'antd';
import {ExclamationCircleOutlined} from '@ant-design/icons';
import { useEffect, useState ,useCallback} from 'react';
import {API,getFetch,postFetch} from '../../axios/api';
import {utils,fileType} from '../../util/util';
import axios from "axios";
import styles from './style.module.css';
import CallList from './components/callList';
import {useRecoilValue,useSetRecoilState } from 'recoil';
import {PlayInformation,PlayState} from "../../store/index";
const {TextArea} = Input;

const defaultPage = {pageNum:1,pageSize:10};

function AddressList() {
  const [addModal,setAddModal] = useState(false);
  const [callListVisible,setCallListVisible] = useState(false);
  const [uploadLoading,setUploadLoading] = useState(false);
  const [exportLoading,setExportLoading] = useState(false);

  const [modelTitle,setModelTitle] = useState('新建')
  const [addressBookList,setAddressBookList] = useState([]);
  const [listParams,setListParams] = useState({...defaultPage});
  const [addressBookData,setAddressBookData] = useState({totalRows:0,pageNum:0,pageSize:10});
  const [callListId,setCallKistId] = useState(null);

  const [form] = Form.useForm();
  const [createForm] = Form.useForm();
  
  const playState = useRecoilValue(PlayState);
  const setPlayInformation = useSetRecoilState(PlayInformation);

  const columns = [
    {title: '联系人ID',dataIndex:'id',width:120},
    {title: '姓名',dataIndex:'name',width:120},
    {title: '手机号码',dataIndex:'mobile',width:120},
    {title: '创建时间',dataIndex:'createTime',width:200},
    {title: '更新时间',dataIndex:'updateTime',width:200},
    {title: '添加人',dataIndex:'createdBy',width:120},
    {title: '最近联系时间',dataIndex:'lastCallTime',width:200},
    {title: '备注',dataIndex:'remark',width:200},
    {title: '操作',dataIndex:'',width:200,render:(text, record, index)=>{
      return <Space wrap={true}>
        <Button type='primary' disabled={!(playState===1)} onClick={()=>{
          Modal.confirm({
            title:"拨号提示",
            okText:"确定",
            cancelText:"取消",
            content:`确定拨打手机号为'${record.mobile}'的用户吗？`,
            onCancel:()=>{},
            onOk:()=>{
              playMobileEvent(record)
            }
          })
        }}>拨打</Button>
        <Button type='primary' onClick={()=>{
            setCallListVisible(true);
            setCallKistId(record.id);
          }}>呼叫记录</Button>
        <Button type='primary' onClick={()=>{editEvent(record)}}>编辑</Button>
        <Button type='primary' onClick={()=>{deleteEvent(record)}}>删除</Button>
      </Space>
    }},
  ];
  const uploadProp = {
    name: 'file',
    accept:'.xlsx',
    action:`${process.env.REACT_APP_DOMAIN_API}${API.addressBookImport}`,
    headers:{
      Authorization:'Bearer ' + utils.getCookie('token')
    },
    showUploadList:false,
    onChange:({file,fileList,event})=>{
      if (file.status !== 'uploading') {
        setUploadLoading(true)
      }
      if (file.status === 'done') {
        if(file.response.resultCode === 0){
          message.success(`${file.name} 上传成功`);
        }else{
          message.error(`${file.name} 上传失败`);
        }
        setUploadLoading(false)
        getAddressBookList();
      } else if (file.status === 'error') {
        message.error(`${file.name} 上传失败`);
        setUploadLoading(false)
      }
    }
  };
  /** 获取表格数据 */
  const getAddressBookList = (params)=>{
    let param ={...listParams,...params};
    getFetch(API.addressBookList,{...param}).then((res)=>{
      if(res){
        setAddressBookList([...res.data.data.records])
        setAddressBookData({...res.data.data})
        setListParams({pageNum:res.data.data.pageNum,pageSize:res.data.data.pageSize})
      }else{
        setAddressBookData({})
        setAddressBookList([])
      }
    })
  }
  useEffect(()=>{
    getAddressBookList();
  },[])
  const editEvent = (record)=>{
    setModelTitle('编辑')
    setAddModal(true)
    createForm.setFieldsValue({...record})
  }
  const updateEvent = (e)=>{
    postFetch(API.addressBookUpdate,{...e}).then((res)=>{
      if(res){
        if(res.data.resultCode === 0){
          message.success('通讯录编辑成功')
          createForm.resetFields();
          form.resetFields();
          getAddressBookList();
        }else{
          message.success(res.data.resultMsg)
        }
      }else{
        message.error('通讯录编辑失败！')
      }
    }).finally(()=>{
      setAddModal(false)
    })
  }
  const deleteEvent = (record)=>{
    Modal.confirm({
      title: `删除${record.name}`,
      icon: <ExclamationCircleOutlined />,
      content: `确定删除${record.name}这条通讯录`,
      okText: '确认',
      cancelText: '取消',
      onOk:()=>{
        postFetch(API.addressBookDelete,{id:record.id}).then((res)=>{
          if(res){
            if(res.data.resultCode === 0){
              message.success('删除成功')
              form.submit();
              // getAddressBookList();
            }else{
              message.error(res.data.resultMsg);
            }
          }else{
            message.error('删除失败')
          }
        })
      }
    });
  }
  const okEvent = (e)=>{
    postFetch(API.addressBookCreate,{...e}).then((res)=>{
      if(res){
        if(res.data.resultCode === 0){
          message.success('通讯录新建成功')
          createForm.resetFields();
          form.resetFields();
          getAddressBookList()
        }else{
          message.error(res.data.resultMsg)
        }
      }else{
        message.error('通讯录新建失败')
      }
    }).finally(()=>{
      setAddModal(false)
    })
  }
  const checkEvent = (e)=>{
    setListParams({...defaultPage,...e})
    getAddressBookList({...e,...defaultPage});
  }
  const createEvent = ()=>{
    setModelTitle('新建')
    setAddModal(true)
  }
  const pagesChangeEvent = (page)=>{
    getAddressBookList({pageNum:page})
  }

  const exportEvent = useCallback(()=>{
    setExportLoading(true);
    const params = { contactsId:utils.getStorage('contactsId')};
    if(listParams.keyWord){
      params['params'] = listParams.keyWord;
    }
    axios.get(`${process.env.REACT_APP_DOMAIN_API}${API.addressBookExportTemplate}`,
      {
        params:{...params},
        responseType: 'blob',
        headers:{
          'Authorization':'Bearer ' + utils.getCookie('token')
        }
    }).then((res)=>{
      if(res){
        if(!res.data.resultCode){
          fileType.toExcel(res)
        }else{
          message.error(res.data.resultMsg)
        }
      }else{
        message.error("导出模板失败！")
      }
    }).finally(()=>{
      setExportLoading(false);
    })
  },[listParams])
  /** 拨号事件 */
  const playMobileEvent = (records)=>{
    postFetch(API.addressBookCall,{id:records.id,mobile:records.mobile}).then((res)=>{
      if(res){
        if(res.data.resultCode === 0){
          setPlayInformation(()=>{
            return {...records,...res.data.data};
          });
        }else{
          message.error(res.data.resultMsg)
        }
      }else{
        message.error("拨号失败")
      }
    })
  }
  return (
    <div>
        <Breadcrumb>
          <Breadcrumb.Item className={styles.breadCont}>通讯录管理</Breadcrumb.Item>
        </Breadcrumb>
        <div className={styles.container}>
          <Form className={styles.formContent} style={{marginBottom:16}} form={form} onFinish={checkEvent}>
            <Space>
              <Form.Item name='keyWord' label="手机号/姓名" style={{margin:0}}>
                <Input placeholder='手机号/姓名' style={{width:200}}/>
              </Form.Item>
              <Form.Item style={{margin:0}}>
                <Button type='primary' onClick={()=>{form.submit()}}>查询</Button>
              </Form.Item>
            </Space>
            <Space>
              <Button type='primary' onClick={createEvent}>新建</Button>
              <Upload {...uploadProp}>
                {
                  uploadLoading?<Button type='primary' loading>导入中...</Button>:<Button type='primary'>导入</Button>
                }
              </Upload>
                {
                  exportLoading?<Button type='text' loading>导出中...</Button>:<Button type='text' onClick={exportEvent}>导出模板</Button>
                }
            </Space>
          </Form>
          <Table dataSource={[...addressBookList]} 
            columns={columns} bordered
            scroll={{x:1400}}
            rowKey="id"
            pagination={{
              total:addressBookData.totalRows,
              pageSize:addressBookData.pageSize,
              onChange:pagesChangeEvent,
              showTotal:(total)=><span>总共{total}条数据</span>,
              showSizeChanger:false,
            }}
          />
        </div>
        <Modal title={`${modelTitle}联系人`} visible={addModal} cancelText="取消" okText="保存" onOk={()=>{createForm.submit()}} onCancel={()=>{setAddModal(false)}} >
            <Form labelCol={{ span: 4 }} wrapperCol={{ span:18}} form={createForm} onFinish={modelTitle === '编辑'?updateEvent:okEvent}>
              {
                modelTitle === '编辑' && <Form.Item name="id" label="id" rules={[{required:true,message:'姓名不能为空'}]} style={{display:'none'}}>
                                          <Input></Input>
                                        </Form.Item>
              }
              <Form.Item name="name" label="姓名" rules={[{required:true,message:'姓名不能为空'}]}>
                <Input maxLength={10}></Input>
              </Form.Item>
              <Form.Item name="mobile" label="手机号" rules={[{required:true,message:'手机号不能为空'},{validator:(_,value)=>{
                                const regExp = new RegExp(/^[1]([3-9])[0-9]{9}$/);
                                if(regExp.test(value)){
                                    return Promise.resolve();
                                }else{
                                    return Promise.reject(new Error('请输入正确的手机号'));
                                }
                            }}]}>
                <Input></Input>
              </Form.Item>
              <Form.Item name="remark" label="备注">
                <TextArea></TextArea>
              </Form.Item>
            </Form>
        </Modal>
        <CallList callListVisible={callListVisible} setCallListVisible={setCallListVisible} contactsId={callListId}/>
        <audio id="audio"></audio>
    </div>
  );
}

export default AddressList;
