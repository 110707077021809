import {Layout,Menu} from 'antd';
import { UserOutlined } from '@ant-design/icons';
import {utils} from '../../util/util';
import styles from './style.module.css';
import {Outlet,Link,useNavigate} from 'react-router-dom';
import {routes} from '../../routers/index';
import Headers from '../../components/Headers/index';
import { useEffect, useState } from 'react';
const { Header, Content, Sider } = Layout;

// interface menuClickProps{
//   item:unknown,
//   key:string,
// }

function DefaultLayout() {
  const [slideRoute,setSlideRoute] = useState({...routes[0]});
  let navigate = useNavigate();
  const [menuActive,setMenuActive] = useState(['']);
  
  useEffect(()=>{
    let path = utils.getStorage('path')?utils.getStorage('path'):'address-list';
    navigate(`/layout/${path}`);
  },[navigate])
  useEffect(()=>{
    let path = utils.getStorage('path')?utils.getStorage('path'):'address-list';
    let userInfo = {};
    try {
      userInfo = JSON.parse(utils.getStorage('userInfo'));
    } catch (error) {
      navigate('/')
    }
    if(userInfo.role ==='user'){
      slideRoute.children = slideRoute.children.filter(item=>{
        return item.path !== 'member-management'
      })
      setSlideRoute(slideRoute)
    }
    setMenuActive([path])
  },[])
  const menuClick = (item)=>{
    utils.setStorage('path',item.key)
    setMenuActive([item.key])
  }
  return (<>
    <Layout className={styles.layoutContainer}>
      <Sider className={styles.silderContainer} collapsible>
        <div className={styles.logo}>外呼工具</div>
        <Menu theme="dark" mode="inline" onClick={menuClick} selectedKeys={[...menuActive]}>
          {
            slideRoute.children?.map((item,index)=>{
              return <Menu.Item key={item.path} icon={<UserOutlined />}><Link to={`${item.path}`}>{item.title}</Link></Menu.Item>
            })
          }
        </Menu>
      </Sider>
      <Layout style={{minWidth:1200,overflow:'auto'}}>
        <Header className={styles.siteLayoutBackground} style={{backgroundColor:'#fff',zIndex:10000}}><Headers/></Header>
        <Content style={{height:'calc(100% - 64px)',overflow:'auto'}}>
          <Outlet/>
        </Content>
      </Layout>
    </Layout>
  </>);
}
export default DefaultLayout;
